import React from 'react'
import Container from '../ui/Container'
import Heading from '../ui/Heading'
import Slider from 'react-slick'
import { Image } from 'react-bootstrap'

import vintage from "../../../../Assets/images/swales-img/ring-style/vintage.png"
import stackable from "../../../../Assets/images/swales-img/ring-style/stackable.png"
import solitaire from "../../../../Assets/images/swales-img/ring-style/solitaire.png"
import sidestone from "../../../../Assets/images/swales-img/ring-style/sidestone.png"
import pave from "../../../../Assets/images/swales-img/ring-style/pave.png"
// import vintage from "../../../../Assets/images/swales-img/ring-style/vintage.png"
// import Solitaire from "../Assets/img/ring-style/Solitaire.jpg"
// import ThreeStone from "../Assets/img/ring-style/3 stone.jpg"
// import SingleRow from "../Assets/img/ring-style/Single Row.jpg"
// import MultiRow from "../Assets/img/ring-style/Multirow.jpg"
// import ByPass from "../Assets/img/ring-style/bypass.jpg"
import { Link } from 'react-router-dom/cjs/react-router-dom'

import SlickLeft from "../../../../Assets/images/swales-img/left-arrow.png"
import SlickRight from "../../../../Assets/images/swales-img/right-arrow.png"

const ShopRingByStyle = () => {
    let settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        prevArrow: <Image src={SlickLeft} alt='' />,
        nextArrow: <Image src={SlickRight} alt=''  />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: true,
                    dots: false,
                }
            }
        ]
    };  

  return (
    <section className='shop-ring-by-style section-space'>
        <Container>
            <Heading className='text-center'>
                <h2>Ring Styles</h2>
                <p>Explore Unique Ring Styles</p>
            </Heading>

            
            <Slider {...settings2}>
                <div className='item'>
                <Link to='/engagement-rings/style=Vintage'><Image src={vintage} alt="vintage" /></Link>
                    <Link to='/engagement-rings/style=Vintage'>Vintage</Link>
                </div>
                <div className='item'>
                <Link to='/jewellery/wedding-rings/style=Stackable'><Image src={stackable} alt="stackable" /></Link>
                    <Link to='/jewellery/wedding-rings/style=Stackable'>Stackable</Link>
                </div>
                <div className='item'>
                <Link to='/engagement-rings/style=Solitaire'><Image src={solitaire} alt="solitaire" /></Link>
                    <Link to='/engagement-rings/style=Solitaire'>Solitaire</Link>
                </div>
                <div className='item'>
                <Link to='/engagement-rings/style=Three stone'><Image src={sidestone} alt="Sidestone" /></Link>
                    <Link to='/engagement-rings/style=Three stone'>Sidestone</Link>
                </div>
                <div className='item'>
                <Link to='/engagement-rings/style=Pave'><Image src={pave} alt="pave" /></Link>
                    <Link to='/engagement-rings/style=Pave'>pave</Link> 
                </div>
                {/* <div className='item'>
                <Link to='/engagement-rings/style=Pave'><Image src={pave} alt="pave" /></Link>
                    <Link to='/engagement-rings/style=Pave'>pave</Link>
                </div> */}
                {/* <div className='item'>
                <Link to='/engagement-rings/style=Multirow'><Image src={pave} alt="Pave" /></Link>
                    <Link to='/engagement-rings/style=Multirow'>Pave</Link>
                </div>
                <div className='item'>
                <Link to='/engagement-rings/style=Bypass'><Image src={ByPass} alt="Bypass" /></Link>
                    <Link to='/engagement-rings/style=Bypass'>Bypass</Link>
                </div>
                <div className='item'>
                <Link to='/engagement-rings/style=Solitaire'><Image src={Solitaire} alt="Solitaire" /></Link>
                    <Link to='/engagement-rings/style=Solitaire'>Solitaire</Link>
                </div> */}
            </Slider>
        </Container>
    </section>
  )
}

export default ShopRingByStyle